
import { CreateEntryContainer } from 'modules/Entries'
import React from 'react'

type Props = {}

const CreateEntry = (props: Props) => {
    return (
        <CreateEntryContainer />
    )
}

export default CreateEntry