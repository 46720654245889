import React from "react";
import {
  Box,
  Select,
  MenuItem,
  Typography,
  FormHelperText,
} from "@mui/material";
import { Field } from "formik";

interface Option {
  value: string;
  label: string;
}

interface RenderSelectProps {
  name: string;
  label: string;
  options: Option[];
  required?: boolean;
}

const RenderSelect: React.FC<RenderSelectProps> = ({
  name,
  label,
  options,
  required = false,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{ marginBottom: "20px" }}
    >
      <Typography sx={styleSheet.labelText}>
        {label} {required && "*"}
      </Typography>
      <Field
        name={name}
        render={({ field, meta }: any) => (
          <>
            <Select {...field} sx={{ width: "351px" }}>
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {meta.touched && meta.error && (
              <FormHelperText error>{meta.error}</FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default RenderSelect;

const styleSheet = {
  labelText: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
    lineHeight: "19.2px",
  },
};
