import { DateRange } from "types";
import React, { useRef, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { CBox, DateRangePicker } from "components";
import UploadSongsDialog from "../UploadSongsDialog";
import PlaceIcon from "@mui/icons-material/Place";
import LocationTable from "../LocationTable";
import CreateLocationModal from "../CreateLocationModal";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Location } from "models/schema";
import { useAuthContext } from "context/AuthContext";
const LocationData = ({ locations }: { locations: Location[] }) => {
  const [dateRange, setDateRange] = useState<DateRange>({
    from: null,
    to: null,
  });
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadSongsModal, setUploadSongsModal] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [editingLocation, setEditingLocation] = useState<any>(null);
  const { user } = useAuthContext();
  // Function to open the modal in "create" mode
  const handleCreateLocation = () => {
    setModalMode("create");
    setModalOpen(true);
    setEditingLocation(null); // Clear any editing data
  };

  // Function to open the modal in "edit" mode
  const handleEditLocation = (location: any) => {
    setModalMode("edit");
    setModalOpen(true);
    setEditingLocation(location); // Pass the location data to the modal for editing
  };

  // Function to open the modal
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setSelectedFiles(filesArray);
      filesArray.forEach((file) => {
        console.log(file);
      });
      setUploadSongsModal(true);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCloseSongsModal = () => {
    setUploadSongsModal(false);
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleExportClick = () => {
    if (locations.length === 0) {
      console.warn("No data to export");
      return;
    }

    // Manually define headers for the CSV
    const headers = [
      "Location RC",
      "Location Name",
      "Stand #",
      "Stand Name",
      "Rates",
      "Conditional Rates",
    ];

    // Format the rows
    const rows = locations.map((row) => [
      row.locationRc,
      row.locationName,
      row.standNumber,
      row.standName,
      row.rates.join(", "), // Convert array of rates to a comma-separated string
      row.conditionalRates
        .map((rate) => `${rate.rate1} & ${rate.rate2}`)
        .join(", "), // Convert conditional rates to a string
    ]);

    // Combine headers and rows into CSV format
    const csvContent = [
      headers.join(","), // Convert headers array to CSV string
      ...rows.map((row) => row.join(",")), // Convert each row array to CSV string
    ].join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element to download the Blob as a file
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "location-data.csv");

    // Append the link to the document body and simulate a click to trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up the link after download
    document.body.removeChild(link);
  };

  // Function to export table data as PDF
  const handleExportClickPDF = () => {
    // Initialize jsPDF with landscape orientation
    const doc = new jsPDF({ orientation: "landscape" });

    if (locations.length === 0) {
      console.warn("No data to export");
      return;
    }

    // Extract headers (keys) and values from tableData
    const headers = [
      "Location RC",
      "Location Name",
      "Stand #",
      "Stand Name",
      "Rates",
      "Conditional Rates",
    ]; // Manually define headers for the PDF

    // Format the rows
    const rows = locations.map((row) => [
      row.locationRc,
      row.locationName,
      row.standNumber,
      row.standName,
      row.rates.join(", "), // Convert array of rates to a comma-separated string
      row.conditionalRates
        .map((rate) => `${rate.rate1} & ${rate.rate2}`)
        .join(", "), // Convert conditional rates to a string
    ]);

    // Call autoTable and pass the jsPDF instance
    autoTable(doc, {
      head: [headers],
      body: rows,
    });

    // Save the PDF
    doc.save("location-data.pdf");
  };

  return (
    <React.Fragment>
      <Box>
        <CBox
          jsb
          as
          wrp
          px={{ xs: "0px", md: 4 }}
          display={"flex"}
          alignItems={"center"}
        >
          <Box fontSize={{ xs: 20, md: 22 }} fontWeight={400}>
            Location and Rate Management
          </Box>

          <CBox
            as
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <CBox col>
              {user?.role === "Admin" && (
                <Button
                  variant="contained"
                  // onClick={handleOpenModal}
                  onClick={handleCreateLocation}
                  sx={styleSheet.createBtn}
                >
                  <PlaceIcon sx={{ width: "19px", height: "19px" }} />
                  <span style={{ marginLeft: "10px" }}>
                    Create New Location
                  </span>
                </Button>
              )}
            </CBox>
            <CreateLocationModal
              open={isModalOpen}
              onClose={handleCloseModal}
              mode={modalMode} // Pass the current mode ("create" or "edit")
              location={editingLocation} // Pass the location data if editing
            />
            <Box>
              <Button
                variant="contained"
                // onClick={handleUploadClick}
                onClick={handleExportClick}
                sx={styleSheet.exportBtn}
              >
                <Box
                  component="img"
                  src="assetsOaks/icon/dowload.svg"
                  alt="export"
                  sx={{
                    width: "19px",
                    height: "19px",
                    objectFit: "contain",
                  }}
                ></Box>
                Export
              </Button>
            </Box>
          </CBox>
        </CBox>
        <Box
          borderTop="2px solid #E0E0E0"
          sx={{
            width: "100%",
            mt: 3,
          }}
        ></Box>
      </Box>
      <LocationTable
        handleEditLocation={handleEditLocation}
        getTableData={locations}
      />
      <UploadSongsDialog
        open={uploadSongsModal}
        onClose={handleCloseSongsModal}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
      />
    </React.Fragment>
  );
};

export default LocationData;

const styleSheet = {
  createBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#0A9959",
    color: "#fff",
  },
  exportBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    gap: "5px",
    backgroundColor: "#0A9959",
    color: "#fff",
  },
};
