import { ImportContainer } from "modules/Entries";

import React from "react";

type Props = {};

const Import = (props: Props) => {
  return <ImportContainer />;
};

export default Import;
