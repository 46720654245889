import { ManageUserContainer } from 'modules/ManageUser'
import React from 'react'

type Props = {}

const ManageUser = (props: Props) => {
    return (
        <ManageUserContainer />
    )
}

export default ManageUser