import { Box, Button, InputAdornment, TextField } from "@mui/material";
import { CBox } from "components";
import React, { useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
import { COLORS } from "constant";
import SearchIcon from "@mui/icons-material/Search";
import CreateUser from "modules/ManageUser/components/CreateUser";
import CommonPopup from "components/CommonPopup/CommonPopup";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { Timestamp } from "firebase/firestore";
import { format } from "date-fns";

// const SearchBar = () => {
const SearchBar = ({
  onSearchChange,
  users,
  locations,
}: {
  onSearchChange: (query: string) => void;
  users: any[];
  locations: any[];
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenModal = (mode: "create" | "edit", user?: any) => {
    setModalMode(mode);
    setSelectedUser(user || null);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleCreateUserClick = () => {
    // Open the modal to create a user
    setModalOpen(true);
    setModalMode("create");
    setSelectedUser(null);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value); // Call the handler with the input value
  };
  const formatDate = (date: any) => {
    if (!date) return ""; // Handle cases where date is undefined or null

    // Check if the date is a Firestore Timestamp
    if (date instanceof Timestamp) {
      const jsDate = date.toDate(); // Convert Firestore Timestamp to JavaScript Date
      return format(jsDate, "MM/dd/yyyy hh:mm a"); // Format the JavaScript Date to MM/DD/YYYY
    }

    return ""; // Return 'Invalid Date' if the date is not a Timestamp
  };

  const handleExportPDF = () => {
    // Check if there are users to export
    if (users.length === 0) {
      alert("No user data available to export");
      return;
    }

    const doc = new jsPDF();

    // Define the table header and rows
    const headers = [
      ["Name", "Email", "Role", "Status", "Phone", "Last Login"],
    ]; // Adjust the headers as per the user data
    const rows = users.map((user: any) => [
      user.name || "N/A",
      user.email || "N/A",
      user.role || "N/A",
      user.status || "N/A",
      user.phone || "N/A",
      user.lastLogin || "N/A",
    ]);

    // Use autoTable to add the table to the PDF
    autoTable(doc, {
      head: headers,
      body: rows,
      margin: { top: 20 },
    });

    // Save the PDF
    doc.save("admin_data.pdf");
  };
  const handleExportCSV = () => {
    // Check if there are users to export
    if (users.length === 0) {
      alert("No user data available to export");
      return;
    }

    // Define the CSV headers
    const headers = ["Name", "Email", "Role", "Status", "Phone", "Last Login"];

    // Format the rows
    const rows = users.map((user: any) => [
      user.name || "N/A",
      user.email || "N/A",
      user.role || "N/A",
      user.status || "N/A",
      user.phone || "N/A",
      formatDate(user.lastLogin) || "N/A",
    ]);

    // Combine headers and rows into CSV format
    const csvContent = [
      headers.join(","), // Convert headers array to CSV string
      ...rows.map((row) => row.join(",")), // Convert each row array to CSV string
    ].join("\n");

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Create a link element to download the Blob as a file
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "admin_data.csv");

    // Append the link to the document body and simulate a click to trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up the link after download
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <CBox jsb as wrp display={"flex"} alignItems={"center"}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Search for users by name or email"
          onChange={handleInputChange}
          sx={{ maxWidth: "394px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="/assetsOaks/icon/search.svg" />
              </InputAdornment>
            ),
            style: { backgroundColor: COLORS.white.main, borderRadius: 7 },
          }}
        />
        <CBox as>
          <CBox col>
            <Button
              onClick={handleCreateUserClick}
              variant="contained"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                backgroundColor: "#0A9959",
                color: "#fff",
              }}
            >
              <img
                src="/assetsOaks/icon/plusbtn.svg"
                style={{ width: "19px", height: "19px" }}
              />
              <span style={{ marginLeft: "10px" }}>Create User</span>
            </Button>
          </CBox>
          <CreateUser
            locations={locations}
            open={isModalOpen}
            onClose={handleCloseModal}
            mode={modalMode}
            userData={selectedUser}
          />
          <Box>
            <Button
              variant="contained"
              onClick={handleExportCSV}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                gap: "5px",
                backgroundColor: "#0A9959",
                color: "#fff",
              }}
            >
              <Box
                component="img"
                src="assetsOaks/icon/dowload.svg"
                alt="export"
                sx={{
                  width: "19px",
                  height: "19px",
                  objectFit: "contain",
                }}
              ></Box>
              Export
            </Button>
          </Box>
        </CBox>
      </CBox>
      <Box
        borderTop="2px solid #E0E0E0"
        sx={{
          width: "100%",
          pb: 3,
          mt: 0,
        }}
      ></Box>
    </React.Fragment>
  );
};

export default SearchBar;
