import { EntriesContainer } from 'modules/Entries'
import React from 'react'

type Props = {}

const Entries = (props: Props) => {
  return (
    <EntriesContainer />
  )
}

export default Entries