import React from "react";
import { Box, Typography, FormHelperText } from "@mui/material";
import { Field } from "formik";
import TextFieldCustom from "components/TextFieldCustom"; // Assuming this is your custom TextField component

interface RenderFieldProps {
  name: string;
  label: string;
  placeholder: string;
  type?: string;
  required?: boolean;
}

const RenderField: React.FC<RenderFieldProps> = ({
  name,
  label,
  placeholder,
  type = "text",
  required = false,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{ marginBottom: "20px" }}
    >
      <Typography sx={styleSheet.labelText}>
        {label} {required && "*"}
      </Typography>
      <Field
        name={name}
        render={({ field, meta }: any) => (
          <>
            <TextFieldCustom
              {...field} // Spread Formik field props (value, onChange, etc.)
              placeholder={placeholder}
              type={type}
              sx={{
                width: "351px",
                "& .MuiInputBase-input::placeholder": {
                  color: "rgba(0, 0, 0, 0.6)",
                },
              }}
            />
            {meta.touched && meta.error && (
              <FormHelperText error>{meta.error}</FormHelperText>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default RenderField;

const styleSheet = {
  labelText: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
    lineHeight: "19.2px",
  },
};
