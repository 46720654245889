import React from "react";
import { IForgetPasswordFormType } from "types";
import {
  AuthLayout,
} from "modules/Authentication/components";
import { ForgetPasswordsForm } from "modules/Authentication/components/ForgetPasswordsForm/ForgetPasswordsForm";

type Props = {};

const ForgetPasswordsContainer = (props: Props) => {


  return (
    <AuthLayout>
      <ForgetPasswordsForm

      />
    </AuthLayout>
  );
};

export default ForgetPasswordsContainer;
