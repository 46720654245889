import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ILoginFormSchema } from "schema";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "constant";
import * as yup from "yup";
import { QueryType } from "hooks/useService/types";
import { AuthService } from "services/Auth.Services";
import useService from "hooks/useService";
import { useNotification } from "../../../../context/notificationContext";
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from "@mui/icons-material/Visibility";
import { useAuthContext } from "context/AuthContext";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ProfileService } from "services/Profile.Services";
export const loginSchema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
  rememberMe: yup.boolean().default(false),
});

interface ILoginFormType {
  email: string;
  password: string;
  rememberMe: boolean;
}

type Props = {};
export const LoginForm = (props: Props) => {
  const navigate = useNavigate();
  const { setAlert } = useNotification();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const { user } = useAuthContext();
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormType>({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
  });

  const { onRequest, isLoading, error } = useService({
    type: QueryType.MUTATION,
    onRequestService: AuthService.login,
    onError: (error) => {
      setAlert({
        show: true,
        message: error.message,
      });
    },
    onSuccess: () => {
      // const users = await ProfileService.getAdminByEmail(values.email);
      // console.log("test", users);
      // if (users.length > 0 && users[0].status === "Inactive") {
      //   setAlert({
      //     show: true,
      //     message: "Your account is inactive. Please contact support.",
      //   });
      //   return; // Exit the function to prevent navigation
      // }
      // console.log("user", email);
      // alert("Logged in successfully");
    },
  });

  const formSubmitHandler: SubmitHandler<ILoginFormType> = async (values) => {
    try {
      setEmail(values.email);
      await onRequest({
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      });

      navigate(ROUTES.ENTRIES);

      //
    } catch (err) {
      console.error("Error logging in:", err);
      // Error is handled in the onError callback in the useService hook
    }
  };

  const { userLoading } = useAuthContext();

  if (userLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <React.Fragment>
      <form>
        <Grid mt={5} container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register("email")}
              type="email"
              variant="outlined"
              fullWidth
              placeholder="Enter Email"
              sx={{
                border: "1px solid black",
                borderRadius: "8px",
                marginTop: "0px",
                "& .MuiInputBase-input::placeholder": {
                  color: "black",
                  opacity: 1,
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderRadius: "8px",
                  },
                },
              }}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>

          <Grid item xs={12} mt={1}>
            <TextField
              {...register("password")}
              type={showPassword ? "text" : "password"}
              fullWidth
              placeholder="Enter Password"
              sx={{
                border: "1px solid black",
                borderRadius: "8px",
                "& .MuiInputBase-input::placeholder": {
                  color: "black",
                  opacity: 1,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  {...register("rememberMe")}
                  sx={{
                    "&.Mui-checked": {
                      color: "#000000",
                    },
                  }}
                />
              }
              label="Remember me"
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "#343434",
                  fontSize: "16px",
                  fontWeight: 400,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign="left" sx={{ color: "#343434" }}>
            <Link to={ROUTES.IDENTIFY_USER} style={styles.link}>
              Forgot password?
            </Link>
          </Grid>
          <Grid mt={2} item xs={12}>
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit(formSubmitHandler)}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                backgroundColor: "#0A9959",
                borderRadius: "8px",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0A9959",
                },
              }}
            >
              Log In
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const styles = {
  link: {
    color: "#343434",
    textDecoration: "underline",
    fontWeight: "400",
    fontSize: "16px",
  },
};
