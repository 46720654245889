import React, { useState, useMemo } from "react";
import {
  Box,
  Table,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableContainer,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { format } from "date-fns";
import { Location } from "models/schema";

interface Song {
  id: number;
  startDate: Timestamp;
  endDate: Timestamp;
  locationId: string;
  location: Location;
  stand: string;
  totalCars: number;
  totalRevenue: number;
  totalValidationCars: number;
  totalValidationRevenue: number;
  totalCreditCard: number;
  totalTips: number;
  totalConditinalTips: number;
  totalCcTipsPaid: number;
  totalCreditCardSurcharge: number;
  totalblubar: number;
  totalcalculatedCashTurnIn: number;
  totalDepositAmount: number;
  entryDailyStatistics: any[];
}

type Order = "asc" | "desc";

interface DailyReportTableProps {
  tableData: Song[];
  stands: string;
  triggerExport: boolean; // Detect export trigger from parent
  onExportComplete: () => void; // Callback to reset trigger in parent
}

const DailyReportTable: React.FC<DailyReportTableProps> = ({
  tableData,
  stands,
  triggerExport,
  onExportComplete,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("date");

  const columnMappings: Record<string, string> = {
    DATE: "date",
    "LOCATION RC": "locationRc",
    "LOCATION NAME": "locationName",
    "STAND #": "stand",
    CARS: "totalCars",
    REVENUE: "totalRevenue",
    "VALIDATED CARS": "totalValidationCars",
    "VALIDATION AMOUNT": "totalValidationRevenue",
    "CREDIT CARDS": "totalCreditCard",
    "CONDITIONAL TIPS": "totalConditinalTips",
    "TOTAL TIPS": "totalTips",
    "PAYROLL TIPS": "totalCcTipsPaid",
    "CC SURCHARGE": "totalCreditCardSurcharge",
    "BLUE BAR": "totalblubar",
    CALCULATED: "totalcalculatedCashTurnIn",
    "DEPOSIT AMOUNT": "totalDepositAmount",
  };

  const handleRequestSort = (headCell: string) => {
    const property = columnMappings[headCell];
    setOrder((prevOrder) =>
      orderBy === property && prevOrder === "asc" ? "desc" : "asc"
    );
    setOrderBy(property);
  };

  const formatDate = (date: any) => {
    if (!date) return "Invalid Date";
    if (date instanceof Timestamp) {
      const jsDate = date.toDate();
      return format(jsDate, "MM/dd/yyyy");
    }
    return "Invalid Date";
  };

  // Flatten the table data and aggregate by date and locationRc if stands are hidden
  const aggregatedData = useMemo(() => {
    if (stands === "Stand 1") {
      const groupedByDateAndLocation: Record<string, any> = {};

      tableData.forEach((song) => {
        song.entryDailyStatistics?.forEach((dailyStat) => {
          const dateKey = `${formatDate(dailyStat.date)}_${
            song.location?.locationRc
          }`;

          if (!groupedByDateAndLocation[dateKey]) {
            groupedByDateAndLocation[dateKey] = {
              date: dailyStat.date,
              locationRc: song.location?.locationRc,
              locationName: song.location?.locationName,
              totalCars: 0,
              totalRevenue: 0,
              totalValidationCars: 0,
              totalValidationRevenue: 0,
              totalCreditCard: 0,
              totalConditinalTips: 0,
              totalTips: 0,
              totalCcTipsPaid: 0,
              totalCreditCardSurcharge: 0,
              totalblubar: 0,
              totalcalculatedCashTurnIn: 0,
              totalDepositAmount: 0,
            };
          }

          groupedByDateAndLocation[dateKey].totalCars +=
            parseFloat(dailyStat.totalCars) || 0;
          groupedByDateAndLocation[dateKey].totalRevenue +=
            parseFloat(dailyStat.totalRevenue) || 0;
          groupedByDateAndLocation[dateKey].totalValidationCars +=
            parseFloat(dailyStat.totalValidationCars) || 0;
          groupedByDateAndLocation[dateKey].totalValidationRevenue +=
            parseFloat(dailyStat.totalValidationRevenue) || 0;
          groupedByDateAndLocation[dateKey].totalCreditCard +=
            parseFloat(dailyStat.totalCreditCard) || 0;
          groupedByDateAndLocation[dateKey].totalConditinalTips +=
            parseFloat(dailyStat.totalConditionalTips) || 0;
          groupedByDateAndLocation[dateKey].totalTips +=
            parseFloat(dailyStat.totalReceivedTips) || 0;
          groupedByDateAndLocation[dateKey].totalCcTipsPaid +=
            parseFloat(dailyStat.totalCcTipsPaid) || 0;
          groupedByDateAndLocation[dateKey].totalCreditCardSurcharge +=
            parseFloat(dailyStat.totalCreditCardSurcharge) || 0;
          groupedByDateAndLocation[dateKey].totalblubar +=
            parseFloat(dailyStat.totalBlueBar) || 0;
          groupedByDateAndLocation[dateKey].totalcalculatedCashTurnIn +=
            parseFloat(dailyStat.totalCalculatedCashTurnIn) || 0;
          groupedByDateAndLocation[dateKey].totalDepositAmount +=
            parseFloat(dailyStat.totalDepositAmount) || 0;
        });
      });

      return Object.values(groupedByDateAndLocation);
    }

    return tableData.flatMap((song) =>
      song.entryDailyStatistics?.map((dailyStat) => ({
        date: dailyStat.date,
        locationRc: song.location?.locationRc,
        locationName: song.location?.locationName,
        stand: song.stand,
        totalCars: dailyStat.totalCars,
        totalRevenue: dailyStat.totalRevenue,
        totalValidationCars: dailyStat.totalValidationCars,
        totalValidationRevenue: dailyStat.totalValidationRevenue,
        totalCreditCard: dailyStat.totalCreditCard,
        totalConditinalTips: dailyStat.totalConditionalTips,
        totalTips: dailyStat.totalReceivedTips,
        totalCcTipsPaid: dailyStat.totalCcTipsPaid,
        totalCreditCardSurcharge: dailyStat.totalCreditCardSurcharge,
        totalblubar: dailyStat.totalBlueBar,
        totalcalculatedCashTurnIn: dailyStat.totalCalculatedCashTurnIn,
        totalDepositAmount: dailyStat.totalDepositAmount,
      }))
    );
  }, [tableData, stands]);

  const sortedData = React.useMemo(() => {
    return [...aggregatedData].sort((a, b) => {
      const getValue = (obj: any, path: string): any =>
        path
          .split(".")
          .reduce(
            (o, key) => (o && typeof o === "object" ? o[key] : undefined),
            obj
          );

      const aValue = getValue(a, orderBy);
      const bValue = getValue(b, orderBy);

      if (orderBy === "locationName") {
        const aLocationName =
          typeof aValue === "string" ? aValue.toLowerCase() : "";
        const bLocationName =
          typeof bValue === "string" ? bValue.toLowerCase() : "";

        return order === "asc"
          ? aLocationName.localeCompare(bLocationName)
          : bLocationName.localeCompare(aLocationName);
      }

      const normalizedA =
        typeof aValue === "string" ? parseFloat(aValue) || 0 : aValue;
      const normalizedB =
        typeof bValue === "string" ? parseFloat(bValue) || 0 : bValue;

      if (orderBy === "date") {
        const aDate =
          normalizedA instanceof Timestamp
            ? normalizedA.toDate()
            : new Date(normalizedA);
        const bDate =
          normalizedB instanceof Timestamp
            ? normalizedB.toDate()
            : new Date(normalizedB);

        return order === "asc"
          ? aDate.getTime() - bDate.getTime()
          : bDate.getTime() - aDate.getTime();
      }

      if (typeof normalizedA === "number" && typeof normalizedB === "number") {
        return order === "asc"
          ? normalizedA - normalizedB
          : normalizedB - normalizedA;
      }

      if (typeof normalizedA === "string" && typeof normalizedB === "string") {
        return order === "asc"
          ? normalizedA.localeCompare(normalizedB)
          : normalizedB.localeCompare(normalizedA);
      }

      return 0;
    });
  }, [aggregatedData, order, orderBy]);

  const handleExport = () => {
    const headers = Object.keys(columnMappings).filter(
      (key) => stands !== "Stand 1" || key !== "STAND #"
    );

    let csvContent = headers.join(",") + "\n";

    sortedData.forEach((row) => {
      const rowData = headers.map((header) => {
        const key = columnMappings[header];
        let value = row[key];

        if (key === "date") {
          // Format the date using the formatDate function
          value = formatDate(value);
        }

        if (typeof value === "number") {
          return value.toFixed(2);
        }

        return value ?? "";
      });
      csvContent += rowData.join(",") + "\n";
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "daily-report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    onExportComplete(); // Notify parent to reset the export trigger
  };

  React.useEffect(() => {
    if (triggerExport) {
      handleExport();
    }
  }, [triggerExport]);

  return (
    <React.Fragment>
      <Box
        sx={{
          width: { xs: "100%", md: "calc(100vw - 320px)" },
          overflowX: "auto",
          padding: { xs: "1px", md: "40px" },
          zIndex: 10,
        }}
      >
        <Typography variant="h6" mb={1}>
          Daily Report
        </Typography>
        <TableContainer component={Paper} sx={{ flex: 1 }}>
          <Table className="report-table" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow
                sx={{
                  bgcolor: "#EAEAEA",
                  "& th": {
                    fontSize: "14px",
                    fontWeight: "normal",
                    padding: "8px",
                  },
                }}
              >
                {Object.keys(columnMappings)
                  .filter((key) => stands !== "Stand 1" || key !== "STAND #")
                  .map((headCell, index) => (
                    <TableCell key={index}>
                      <TableSortLabel
                        active={orderBy === columnMappings[headCell]}
                        direction={
                          orderBy === columnMappings[headCell] ? order : "asc"
                        }
                        onClick={() => handleRequestSort(headCell)}
                      >
                        {headCell}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(row?.date)}</TableCell>
                  <TableCell>{row?.locationRc}</TableCell>
                  <TableCell>{row?.locationName}</TableCell>
                  {stands !== "Stand 1" && <TableCell>{row?.stand}</TableCell>}
                  <TableCell>{row?.totalCars || 0}</TableCell>
                  <TableCell>
                    {parseFloat(row?.totalRevenue).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {parseFloat(row?.totalValidationCars).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {parseFloat(row?.totalValidationRevenue).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {parseFloat(row?.totalCreditCard).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {parseFloat(row?.totalConditinalTips).toFixed(2)}
                  </TableCell>
                  <TableCell>{parseFloat(row?.totalTips).toFixed(2)}</TableCell>
                  <TableCell>
                    {parseFloat(row?.totalCcTipsPaid).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {parseFloat(row?.totalCreditCardSurcharge).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {parseFloat(row?.totalblubar).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {parseFloat(row?.totalcalculatedCashTurnIn).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {parseFloat(row?.totalDepositAmount).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  );
};

export default DailyReportTable;
