import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { COLORS } from "constant";
import { SxProps, Theme } from "@mui/material/styles";

type Props = {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  ref?: any;
  // accept?: string;
  rows?: number;
  type?: string;
  label?: string;
  disabled?: boolean;
  multiline?: boolean;
  onFocus?: () => any;
  placeholder?: string;
  defaultValue?: string;
  // sx?: React.CSSProperties;
  sx?: SxProps<Theme>; // Updated to accept responsive styling
};

const TextFieldCustom = (props: Props) => {
  const {
    rows,
    type,
    name,
    value,
    onChange,
    ref,
    // accept,
    label,
    onFocus,
    disabled,
    multiline,
    placeholder,
    defaultValue,
    sx,
  } = props || {};

  return (
    <React.Fragment>
      {label && (
        <InputLabel
          sx={{
            fontWeight: "700",
            fontSize: "18px",
            color: COLORS.gray.main,
          }}
          shrink
        >
          {label}
        </InputLabel>
      )}
      <TextField
        name={name}
        value={value}
        onChange={onChange}
        ref={ref}
        // accept={accept}
        defaultValue={defaultValue}
        rows={rows}
        fullWidth
        type={type}
        variant="outlined"
        sx={{
          width: "100%",
          height: multiline ? "auto" : "64px",
          "& .MuiOutlinedInput-root": {
            borderRadius: "7px",
            height: "100%",
            "& fieldset": {
              border: `1px solid ${"black"}`,
            },
            "&:hover fieldset": {
              borderColor: `${COLORS.gray.main}`,
            },
            "&.Mui-focused fieldset": {
              borderColor: "#87C9E9",
              boxShadow: "0px 0px 0px 4px #97CAD84D",
            },
          },
          // Remove the spinner arrows
          "& input[type=number]": {
            "-moz-appearance": "textfield",
            "-webkit-appearance": "none",
            appearance: "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          // Ensure the input itself is responsive
          "& input": {
            width: "100%", // Ensure input takes full width
          },
          ...sx,
        }}
        disabled={disabled}
        multiline={multiline}
        placeholder={placeholder}
        onFocus={onFocus}
      />
    </React.Fragment>
  );
};

export default TextFieldCustom;
