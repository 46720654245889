import React from "react";
import { ROUTES } from "constant";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import { Login } from "screens";
import ForgetPasswords from "screens/ForgetPasswords/ForgetPasswords";
import EnterCode from "screens/EnterCode/EnterCode";
import ManageUser from "screens/ManageUser/ManageUser";

import AdminAccount from "screens/AdminAccount/AdminAccount";
import { routeWithProtection } from "utils/routeWithProtection";
import SecureRoutes from "hocs/SecureRoutes";
import { useAuthContext } from "context/AuthContext";
import { LinearProgress } from "@mui/material";
import IdentifyUser from "screens/IdentifyUser/Login";
import Location from "screens/Location/Location";
import RevenueReport from "screens/RevenueReport/RevenueReport";
import RevenueReport2 from "screens/RevenueReport/RevenueReport2";

import Entries from "screens/Entries/Entries";
import Import from "screens/Entries/Import";

import CreateEntry from "screens/CreateEntry/CreateEntry";

type Props = {};

const Routes = (props: Props) => {
  const { isLoading } = useAuthContext();

  if (isLoading) return <LinearProgress />;

  return (
    <ReactRoutes>
      <Route element={<SecureRoutes />}>
        {/* <Route path='/dashboard' element={<Dashboard />} /> */}
        {/* <Route path={ROUTES.DASHBOARD} element={<Dashboard />} /> */}
        <Route path="/" element={<Location />} />
        <Route path={ROUTES.REVENUE_REPORT} element={<RevenueReport />} />
        <Route path={ROUTES.REVENUE_REPORT2} element={<RevenueReport2 />} />
        <Route path={ROUTES.ENTRIES} element={<Entries />} />
        <Route path={ROUTES.ENTRIES_IMPORT} element={<Import />} />
        <Route path={ROUTES.ENTRIES_CREATE} element={<CreateEntry />} />
        <Route path={ROUTES.MANAGE_USER} element={<ManageUser />} />
        <Route path={ROUTES.ADMIN_ACCOUNTS} element={<AdminAccount />} />
      </Route>

      <Route
        path={ROUTES.LOGIN}
        element={routeWithProtection(Login, { type: "login" })}
      />
      <Route path={ROUTES.FORGOT_PASSWORDS} element={<ForgetPasswords />} />
      <Route path={ROUTES.IDENTIFY_USER} element={<IdentifyUser />} />
      <Route path={ROUTES.ENTER_CODE} element={<EnterCode />} />
    </ReactRoutes>
  );
};

export default Routes;
