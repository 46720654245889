import { DateRange } from "types";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  IconButton,
  Popover,
} from "@mui/material";
import { CBox, DateRangePicker } from "components";

import EntriesTable from "../EntriesTable";
import CreateEntry from "../CreateEntry/CreateEntry";
import CommonPopup from "components/CommonPopup/CommonPopup";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";

const CreateEntryData: React.FC = () => {
  const naviogate = useNavigate();
  const calendarRef = useRef<HTMLDivElement>(null);

  const [location, setLocation] = useState("");
  const [report, setReport] = useState("");
  const [stands, setStands] = useState("");
  const [isCreatingEntry, setIsCreatingEntry] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<any>(null); // State to store the entry being edited
  const [entryMode, setEntryMode] = useState<"create" | "edit">("create");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupClose, setIsPopupClose] = useState(false);
  const [isPopupCreate, setIsPopupCreate] = useState(false);

  const [selectedDate, setSelectedDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [dateRange, setDateRange] = useState({
    start: dayjs().format("MMM D"),
    end: dayjs().add(6, "day").format("MMM D"),
  });
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  // Handle date change from DatePicker
  const handleDateChange = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    setSelectedDate(ranges.selection);
    setDateRange({
      start: dayjs(startDate).format("MMM D"),
      end: dayjs(endDate).format("MMM D"),
    });

    if (startDate && endDate && startDate !== endDate) {
      // setShowDatePicker(false);
    }
  };

  // Close calendar if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setShowDatePicker(false); // close calendar
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Increase date range by 6 days
  const handleIncreaseDate = () => {
    const newStartDate = dayjs(selectedDate.startDate).add(6, "day").toDate();
    const newEndDate = dayjs(newStartDate).add(6, "day").toDate();

    setDateRange({
      start: dayjs(newStartDate).format("MMM D"),
      end: dayjs(newEndDate).format("MMM D"),
    });
    setSelectedDate({
      startDate: newStartDate,
      endDate: newEndDate,
      key: "selection",
    });
  };

  // Decrease date range by 6 days
  const handleDecreaseDate = () => {
    const newStartDate = dayjs(selectedDate.startDate)
      .subtract(6, "day")
      .toDate();
    const newEndDate = dayjs(newStartDate).add(6, "day").toDate();

    setDateRange({
      start: dayjs(newStartDate).format("MMM D"),
      end: dayjs(newEndDate).format("MMM D"),
    });
    setSelectedDate({
      startDate: newStartDate,
      endDate: newEndDate,
      key: "selection",
    });
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };

  const handleCreatePopupEntry = () => {
    naviogate("/entries");
  };

  const handleCreatePopupClose = () => {
    setIsPopupCreate(false);
  };

  const handleCreateEntry = () => {
    setEntryMode("create");
    setSelectedEntry(null);
    // setIsCreatingEntry(true);
    setIsPopupCreate(true);
  };

  const handleBackToEntries = () => {
    setIsCreatingEntry(false);
  };

  const handleConfirmClose = () => {
    // Logic for closing the entries
    console.log("Entries closed.");
    setIsPopupOpen(false);
    setIsPopupClose(true);
  };
  const handleConfirmPopupClose = () => {
    // Logic for closing the entries
    setIsCreatingEntry(false);

    setIsPopupOpen(false);
    setIsPopupClose(false);
  };
  const handleCancelClose = () => {
    // Logic for canceling the action
    console.log("Action canceled.");
    setIsPopupOpen(false);
    setIsCreatingEntry(false);
  };

  return (
    <React.Fragment>
      <Box position={"relative"} sx={{ display: "none" }}>
        <CBox jsb as wrp px={4} display={"flex"} alignItems={"center"} gap={2}>
          <Box></Box>
          <Box display={"flex"} gap={3} alignItems="center">
            <IconButton onClick={handleDecreaseDate}>
              <img src="/assetsOaks/icon/leftArrow.svg" alt="left arrow" />
            </IconButton>
            <Typography fontSize={"30px"} fontWeight={800}>
              {dateRange.start} - {dateRange.end}
            </Typography>
            <IconButton onClick={() => setShowDatePicker(true)}>
              <img src="/assetsOaks/icon/calandar.svg" alt="calendar" />
            </IconButton>
            <IconButton onClick={handleIncreaseDate}>
              <img src="/assetsOaks/icon/rightArrow.svg" alt="right arrow" />
            </IconButton>
          </Box>

          <Button
            variant="contained"
            onClick={handleCreateEntry}
            sx={{
              display: "flex",
              height: "40px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#0A9959",
              color: "#fff",
            }}
          >
            <Box
              component="img"
              src="/assetsOaks/icon/save.svg"
              alt="export"
              sx={{
                width: "19px",
                height: "19px",
                objectFit: "contain",
                marginRight: "7px",
              }}
            />
            Save
          </Button>
        </CBox>
        <Box
          borderTop="2px solid #E0E0E0"
          sx={{
            width: "100%",
            mt: 3,
          }}
        ></Box>
      </Box>

      {/* Conditionally render EntriesTable or CreateEntry */}
      {!isCreatingEntry ? (
        <CreateEntry
          mode={entryMode}
          entryData={selectedEntry}
          onBack={handleBackToEntries}
        />
      ) : (
        <CreateEntry
          mode={entryMode}
          entryData={selectedEntry}
          onBack={handleBackToEntries}
        />
      )}
      {/* Reusable Popup */}
      <CommonPopup
        open={isPopupOpen}
        title={"Are you sure?"}
        message={"Do you want to close all entries listed"}
        messageText={"from DD/MM/YYYY to DD/MM/YYYY?"}
        confirmText={"Yes, close entries."}
        cancelText={"No, cancel."}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
      />
      {/* /// confrim close /// */}
      <CommonPopup
        open={isPopupClose}
        title={"Entries listed from"}
        message={"DD/MM/YYYY - DD/MM/YYYY"}
        messageText={" have been closed."}
        confirmText={"Close"}
        cancelText={""}
        onConfirm={handleConfirmPopupClose}
        onCancel={handleCancelClose}
      />

      {/* /// create entry popup /// */}
      {/* Reusable Popup */}
      <CommonPopup
        open={isPopupCreate}
        title={"A similar entry already exists!"}
        message={" would you like to be redirected to that entry?"}
        messageText={""}
        confirmText={"Yes, please redirect me."}
        cancelText={"No, cancel."}
        onConfirm={handleCreatePopupEntry}
        onCancel={handleCreatePopupClose}
      />

      {/* DatePicker that appears directly */}
      <Box sx={{ position: "absolute", top: "50px", right: "20px" }}>
        <CustomDatePicker
          selectedDate={[selectedDate]}
          onChange={handleDateChange}
          showDatePicker={showDatePicker}
          onClose={handleCloseDatePicker}
          ref={calendarRef}
        />
      </Box>

      {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            // onClose={handleClose}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
         >
            <DatePicker

               value={selectedDate}
               onChange={handleDateChange}
            />
         </Popover> */}
    </React.Fragment>
  );
};

export default CreateEntryData;
