export const COLORS = {
  primary: {
    main: "#6653D3",
    hard: "#133A64",
    light: "#9080eb",
    lightest: "#B6ABE9",
  },
  secondary: {
    main: "#F4F6F7",
    light: "#E6E9EB",
  },
  success: {
    main: "#009A59",
    light: "#E8FAF0",
    strong: "#008557",
  },
  error: {
    light: "#FF3C38",
    strong: "#EF2626",
    thin: "#FAE1E0",
    hard: "#812E13",
  },
  white: {
    main: "#ffffff",
    green: "#0A9959",
  },
  black: {
    main: "#000000",
    light: "#DADDDE",
    thin: "#D5D5D5",
  },
  dark: {
    light: "#2F3133",
    thin: "#6E7375",
  },
  gray: {
    main: "#53575A",
    light: "#898D8F",
    thin: "#D9D9D9",
    thinner: "#EBEBEB",
  },
  gradient: {
    main: "linear-gradient(0deg, #EAF4F7, #EAF4F7)",
  },
};
