import { RevenueContainer2 } from "modules/Revenue";
import React from "react";

type Props = {};

const RevenueReport2 = (props: Props) => {
  return <RevenueContainer2 />;
};

export default RevenueReport2;
