import { createContext, useContext, useState, ReactNode } from "react";
import useService, { ServiceState } from "hooks/useService";
import { QueryType } from "hooks/useService/types";
import { ProfileService } from "services/Profile.Services";
import { User } from "models/schema"; // Assuming User is defined in models
import { FormDataTypeEntry } from "types";

interface DataContextType {
  getUsers: ServiceState<User[]>;
  selectedEntryData: FormDataTypeEntry | null; // To store the form data globally
  setSelectedEntryData: (data: FormDataTypeEntry) => void; // Function to update form data
  setDates: (dates: { startDate: Date; endDate: Date }) => void; // Function to update dates
  dates: { startDate: Date; endDate: Date }; // To store the selected dates globally
}

interface DataProviderProps {
  children: ReactNode;
}

// Create the context
const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  // State to store the selected entry data (location, date, stand)
  const [selectedEntryData, setSelectedEntryData] =
    useState<FormDataTypeEntry | null>(null);

  const [dates, setDates] = useState<{ startDate: Date; endDate: Date }>({
    startDate: new Date(),
    endDate: new Date(),
  });

  // Fetch all users (as per your existing implementation)
  const getUsers = useService<User[]>({
    type: QueryType.MUTATION,
    onRequestService: ProfileService.getAllAdmins2,
    useCache: true,
  });

  return (
    <DataContext.Provider
      value={{
        getUsers,
        selectedEntryData,
        setSelectedEntryData,
        dates,
        setDates,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

// Hook to use the DataContext
export const useDataContext = (): DataContextType => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};
