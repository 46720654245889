// data.js
export const daysOfWeek = [
  { label: "Monday", date: "2/19/2024" },
  { label: "Tuesday", date: "2/20/2024" },
  { label: "Wednesday", date: "2/21/2024" },
  { label: "Thursday", date: "2/22/2024" },
  { label: "Friday", date: "2/23/2024" },
  { label: "Saturday", date: "2/24/2024" },
  { label: "Sunday", date: "2/25/2024" },
];

export const rowHeaders = [
  { label: "$8.00" },
  { label: "$10.00" },
  { label: "$12.00" },
];

export const rowHeaders2 = [{ label: "$20.00" }];

export const rowHeaders3 = [
  { label: "Neiman Marcus", text: "-$10.00" },
  { label: "SAKS", text: "-$10.00" },
  { label: "Neiman Marcu", text: "-$12.00" },
  { label: "SAKS", text: "-$12.00" },
];

export const rowHeaders4 = [
  { label: "CC Tips Paid ", text: "on Paycheck" },
  { label: "Total Credit ", text: "Cards" },
];

export const Credit = [
  { label: "Credit Card ", text: "Surcharge" },
  { label: "Blue Bar " },
];

export const carsValues = [
  { Label: `-` },
  { Label: "-" },
  { Label: "2" },
  { Label: "3" },
  { Label: "5" },
  { Label: "8" },
  { Label: "10" },
];

export const validationValues = [
  { Label: `$-000.00` },
  { Label: "$-000.00" },
  { Label: "$-16.00" },
  { Label: "$-24.00" },
  { Label: "$-50.00" },
  { Label: "$-102.00" },
  { Label: "$-124.00" },
];

export const revenueValuesData = [
  { Label: "$-" },
  { Label: "$-" },
  { Label: "$16.00" },
  { Label: "$24.00" },
  { Label: "$50.00" },
  { Label: "$102.00" },
  { Label: "$124.00" },
];

export const GrossRevenue = [
  { Label: "$-" },
  { Label: "$-" },
  { Label: "$16.00" },
  { Label: "$24.00" },
  { Label: "$50.00" },
  { Label: "$102.00" },
  { Label: "$124.00" },
];
