import { LocationContainer } from 'modules/Location'
import React from 'react'

type Props = {}

const Location = (props: Props) => {
  return (
    <LocationContainer />
  )
}

export default Location