import React, { useEffect, useState } from "react";
import AdminCard from "../AdminCard";
// import { useFirebase } from "hooks";
import { Box, Button, Grid } from "@mui/material";
import { QueryType } from "hooks/useService/types";
import { CBox } from "components";
import AddAccountDialog from "../AddAccountDialog";
import PlaceIcon from "@mui/icons-material/Place";
import { on } from "events";
import CreateUser from "modules/ManageUser/components/CreateUser";
import SearchBar from "./SearchBar";
import { useDataContext } from "context/DataContext";

import { Admin } from "models/schema";

// Dummy Data
const cardData = [
  {
    role: "Super Admin",
    status: "Active",
    name: "Abigail Jones",
    email: "A.Jones@blisstrax.com",
    phone: "123-456-7899",
    lastLogin: "Last Login: MM/DD/YYYY",
    timeLogin: "00:00 AM",
  },
  {
    role: "Admin",
    status: "Pending",
    name: "Abigail Jones",
    email: "ones@blisstrax.com",
    phone: "123-456-7899",
    lastLogin: "Last Login: MM/DD/YYYY",
    timeLogin: "00:00 AM",
  },
  {
    role: "Admin",
    status: "Pending",
    name: "Abigail Jones",
    email: "nes@blisstrax.com",
    phone: "123-456-7899",
    lastLogin: "Last Login: MM/DD/YYYY",
    timeLogin: "00:00 AM",
  },
  {
    role: "Admin",
    status: "Pending",
    name: "Abigail Jones",
    email: "es@blisstrax.com",
    phone: "123-456-7899",
    lastLogin: "Last Login: MM/DD/YYYY",
    timeLogin: "00:00 AM",
  },
  {
    role: "Admin",
    status: "Pending",
    name: "Abigail Jones",
    email: "as@blisstrax.com",
    phone: "123-456-7899",
    lastLogin: "Last Login: MM/DD/YYYY",
    timeLogin: "00:00 AM",
  },
  {
    role: "Admin",
    status: "Pending",
    name: "Abigail Jones",
    email: "wes@blisstrax.com",
    phone: "123-456-7899",
    lastLogin: "Last Login: MM/DD/YYYY",
    timeLogin: "00:00 AM",
  },
  {
    role: "Admin",
    status: "Pending",
    name: "Abigail Jones",
    email: "cs@blisstrax.com",
    phone: "123-456-7899",
    lastLogin: "Last Login: MM/DD/YYYY",
    timeLogin: "00:00 AM",
  },
];

const AdminAccountData = ({
  admins,
  locations,
}: {
  admins: any[];
  locations: any[];
}) => {
  const [open, setOpen] = useState(false);
  const [modalMode, setModalMode] = useState<"create" | "edit">("create");
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Function to handle editing admin
  const handleEditAdmin = (adminData: any) => {
    setSelectedUser(adminData); // Pass the selected admin data to the modal
    setModalMode("edit");
    setOpen(true); // Open the modal in edit mode
  };

  // Function to handle creating new admin
  const handleAddAdmin = () => {
    setSelectedUser(null); // Clear the selected user
    setModalMode("create");
    setOpen(true); // Open the modal in create mode
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query.toLowerCase()); // Update search query
  };

  // Filter the admin cards based on the search query
  const filteredAdmins = admins?.filter(
    (admin: any) =>
      admin.name.toLowerCase().includes(searchQuery) ||
      admin.email.toLowerCase().includes(searchQuery) ||
      admin.role.toLowerCase().includes(searchQuery)
  );

  return (
    <React.Fragment>
      <SearchBar
        locations={locations}
        onSearchChange={handleSearchChange}
        users={filteredAdmins}
      />

      <Box overflow={"auto"} zIndex={10} flex={1} mb={2} pb={2} pr={2}>
        <Grid item container xs={12} spacing={4}>
          {filteredAdmins.length > 0 ? (
            filteredAdmins.map((data: any, index: any) => (
              <Grid item xs={12} sm={12} md={4} key={index}>
                <AdminCard data={data} onEdit={() => handleEditAdmin(data)} />
              </Grid>
            ))
          ) : (
            <div>No results found</div>
          )}
        </Grid>
      </Box>
      <CreateUser
        locations={locations}
        open={open}
        onClose={() => setOpen(false)}
        mode={modalMode}
        userData={selectedUser} // Pass the selected user data when editing
      />
    </React.Fragment>
  );
};

export default AdminAccountData;
