import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  Checkbox,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { RenderField, RenderSelect } from "components";
import { useNotification } from "../../../../context/notificationContext";
import { LocationService } from "services/Location.Services";
import { ProfileService } from "services/Profile.Services";
import {
  createUserFn,
  updateUserFn,
} from "../../../../utils/firebaseFunctions";
import { getAuth } from "firebase/auth";

interface CreateUserProps {
  open: boolean;
  onClose: () => void;
  mode: "create" | "edit";
  userData?: any;
  locations: {
    id: string;
    locationName: string;
    locationRc: string;
    standNumber: string;
  }[];
}

const CreateUser: React.FC<CreateUserProps> = ({
  open,
  onClose,
  mode,
  userData,
  locations,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const { setAlert } = useNotification();
  const [loading, setLoading] = useState(false);
  const [initialLocations, setInitialLocations] = useState<string[]>([]); // State to store fetched locations

  interface FormData {
    uid: string;
    role: string;
    status: string;
    name: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
    locations: string[];
  }

  const initialFormData: FormData = {
    uid: userData?.uid || "",
    role: userData?.role || "",
    status: userData?.status || "",
    name: userData?.name || "",
    email: userData?.email || "",
    phone: userData?.phone || "",
    password: "",
    confirmPassword: "",
    locations: initialLocations, // Use state for initial locations
  };

  const validationSchema = Yup.object({
    role: Yup.string().required("Role is required"),
    name: Yup.string().required("Full Name is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number cannot exceed 15 digits"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&]/,
        "Password must contain at least one special character"
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), undefined],
      "Passwords must match"
    ),
    status: Yup.string().required("Status is required"),
  });

  // Fetch user locations if in edit mode
  useEffect(() => {
    const fetchUserLocations = async (userId: string) => {
      try {
        const userLocations = await LocationService.fetchUserLocations(userId);
        const selectedIds = userLocations.map((loc: any) => loc.id);
        setSelectAll(selectedIds.length === locations.length);
        setInitialLocations(selectedIds); // Set initial locations for Formik
      } catch (error) {
        console.error("Error fetching location users:", error);
      }
    };

    if (userData?.id) {
      fetchUserLocations(userData.id);
    } else {
      setInitialLocations([]); // Reset if no userData
    }
  }, [userData, locations.length, open]);

  const handleSubmit = async (values: typeof initialFormData) => {
    setLoading(true);
    try {
      if (mode === "create") {
        const users = await ProfileService.getUserByEmail(values.email);
        if (users.length === 0) {
          const response = await createUserFn({
            email: values.email,
            password: values.password,
          });
          values.uid = response?.data?.userId;
          await ProfileService.createAdmin(values);
          setAlert({
            show: true,
            message: "User created successfully!",
            variant: "success",
          });
        } else {
          setAlert({
            show: true,
            message: "Email already exists!",
            variant: "error",
          });
        }
      } else if (mode === "edit" && userData?.id) {
        await updateUserFn({
          uid: values.uid,
          email: values.email,
          password: values.password,
        });
        await ProfileService.updateAdmin(userData.id, values);
        setAlert({
          show: true,
          message: "User updated successfully!",
          variant: "success",
        });
      }
      onClose();
    } catch (error) {
      console.error("Error:", error);
      setAlert({
        show: true,
        message: "An error occurred during the process.",
        variant: "error",
      });
    }
    setLoading(false);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styleSheet.container}>
        <Formik
          initialValues={{ ...initialFormData, locations: initialLocations }} // Override locations with fetched data
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            const handleLocationChange = (locationId: string) => {
              const newLocations = values.locations.includes(locationId)
                ? values.locations.filter((id: string) => id !== locationId)
                : [...values.locations, locationId];
              setFieldValue("locations", newLocations);
              setSelectAll(newLocations.length === locations.length);
            };

            const handleSelectAllChange = () => {
              const newSelectAll = !selectAll;
              setSelectAll(newSelectAll);
              setFieldValue(
                "locations",
                newSelectAll ? locations.map((loc) => loc.id) : []
              );
            };

            return (
              <Form>
                <Box px={4}>
                  <Typography variant="h6" component="h2">
                    {mode === "create" ? "Add New Admin" : "Edit Admin"}
                  </Typography>
                  <Box display="flex" gap="110px">
                    <Grid>
                      <RenderSelect
                        name="role"
                        label="Account Type"
                        options={[
                          { value: "Admin", label: "Admin" },
                          { value: "Manager", label: "Manager" },
                          { value: "Associate", label: "Associate" },
                        ]}
                        required
                      />
                      <RenderField
                        name="email"
                        label="Email"
                        placeholder="AJones2@example.com"
                        required
                      />
                      <RenderField
                        name="password"
                        label="Password"
                        type="password"
                        placeholder="*********"
                        required={mode === "create"}
                      />
                      <RenderSelect
                        name="status"
                        label="Status"
                        options={[
                          { value: "Active", label: "Active" },
                          { value: "Inactive", label: "Inactive" },
                        ]}
                        required
                      />
                    </Grid>
                    <Grid>
                      <RenderField
                        name="name"
                        label="Full Name"
                        placeholder="Abigail Jones"
                        required
                      />
                      <RenderField
                        name="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        placeholder="*********"
                        required={mode === "create"}
                      />
                      <RenderField
                        name="phone"
                        label="Phone Number"
                        placeholder="123-456-7899"
                      />
                    </Grid>
                  </Box>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Box display="flex" alignItems="center" gap={2}>
                      <Box sx={styleSheet.labelText}>Locations *</Box>
                      <Box display="flex" alignItems="center" gap={0}>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                          sx={{
                            color: "#000",
                            "&.Mui-checked": { color: "#0A9959" },
                          }}
                        />
                        <Typography>Select All</Typography>
                      </Box>
                    </Box>
                    <Box
                      border="2px solid #000000"
                      borderRadius="8px"
                      p={2}
                      width="770px"
                    >
                      <Grid container spacing={0} gap="0px">
                        {locations.map((location) => (
                          <Grid item key={location.id}>
                            <Checkbox
                              checked={values.locations.includes(location.id)}
                              onChange={() => handleLocationChange(location.id)}
                              size="small"
                              sx={{
                                color: "#000000",
                                paddingRight: "5px",
                                "&.Mui-checked": { color: "#0A9959" },
                              }}
                            />
                            <Typography variant="body2" component="span">
                              {location.locationRc}- {location.locationName}-{" "}
                              {location.standNumber}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 4, textAlign: "center", gap: 2, mb: 2 }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={styleSheet.button}
                    >
                      {loading ? (
                        <>
                          <CircularProgress
                            size={20}
                            sx={{ color: "#fff", marginRight: "10px" }}
                          />
                          Saving User...
                        </>
                      ) : (
                        <>
                          {mode === "create" ? "Add New User" : "Save Changes"}
                        </>
                      )}
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
};

export default CreateUser;

const styleSheet = {
  container: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "85%", md: 887 },
    bgcolor: "background.paper",
    boxShadow: 24,
    maxHeight: "90vh",
    overflowY: "auto",
    pb: 10,
    borderRadius: "10px",
    p: 3,
  },
  labelText: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#000000",
    lineHeight: "19.2px",
  },
  button: {
    height: "40px",
    backgroundColor: "#0A9959",
    color: "#fff",
  },
};
